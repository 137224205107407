// src/Routes.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
//import Paineis from './pages/Paineis';
import Sensores from './pages/Sensores';
import Molduras from './pages/Molduras';
import Acessorios from './pages/Acessorios';
import Gabaritos from './pages/Gabaritos';
import Instaladores from './pages/Instaladores';
import About from './About';
import NaoEncontrado from './pages/NaoEncontrado'
import Carrinho from './pages/Carrinho';
import InformacoesProduto from './pages/InformacoesProduto';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/paineis" element={<Home />} />
        <Route path="/sensores" element={<Sensores />} />
        <Route path="/molduras" element={<Molduras />} />
        <Route path="/acessorios" element={<Acessorios />} />
        <Route path="/gabaritos" element={<Gabaritos />} />
        <Route path="/instaladores" element={<Instaladores />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/carrinho" element={<Carrinho />} />
        <Route path="/produto/:id" element={<InformacoesProduto />} />
        <Route path="*" element={<NaoEncontrado />} /> {/* Rota para tratar 404 */}
      </Routes>
    </Router>
  );
}

export default AppRoutes;
